<template>
    <admin-dashboard-layout :loading="loading">
        <v-card class="mx-15 px-15">
            <v-card-title>
                <v-row>
                    <v-col v-text="marketReport.name"/>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.market-reports.index'}" v-text="'return'"/>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-select
                        v-model="marketReport.language"
                        :items="languages"
                        item-text="label"
                        item-value="code"
                        outlined
                        dense
                        label="Language">
                        <template slot="selection" slot-scope="data">
                            {{ $t('languages.' + data.item.code) }}
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ $t('languages.' + data.item.code) }}
                        </template>
                    </v-select>
                    <v-text-field v-model="marketReport.name" outlined dense label="Name"/>
                    <v-row>
                        <v-col md="4" sm="12">
                            <v-img :src="marketReportAvatar"/>
                        </v-col>
                        <v-col md="8" sm="12">
                            <v-textarea v-model="marketReport.description" outlined label="Description"/>
                            <v-textarea outlined v-model="marketReport.notes" label="Notes"/>
                            <template v-for="(type, index) in files">
                                <AdminUploadFiles
                                    v-model="marketReport[type]"
                                    :name="$_.upperFirst(type).replace('_', ' ')"
                                    :file="marketReport[type]"
                                    directory="market-reports"
                                    :is-multiply="$_.isArray(marketReport[type])"
                                    :key="index"/>
                            </template>
                            <v-text-field label="Rank" v-model="marketReport.rank" outlined dense/>
                            <v-select
                                v-model="marketReport.tags"
                                outlined
                                dense
                                label="Tags"
                                item-text="name"
                                item-value="id"
                                :multiple="true"
                                :items="tags"/>
                            <v-select
                                v-model="marketReport.industry_id"
                                outlined
                                dense
                                label="Industries"
                                item-text="name"
                                item-value="id"
                                :items="industries"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-center">
                            <v-btn @click="handleSave" v-text="'Save'"/>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Tag from "@/models/Tag";
import Industry from "@/models/Industry";
import MarketReport from "@/models/MarketReport";
import AdminUploadFiles from "@/components/admin/AdminUploadFiles";
import languages from "@/configs/languages";
import {mapMutations} from "vuex";


export default {
    name: "Form",
    components: {
        AdminUploadFiles,
        AdminDashboardLayout,
    },
    data: function () {
        return {
            languages,
            loading: true,
            directory: 'market-reports',
            newLanguage: {},
            carousel: 0,
            marketReport: {
                script: '',
                audio: '',
                powerpoint: '',
                individual_slides: [],
                video: '',
            },
            files: ['script', 'audio', 'powerpoint', 'video', 'individual_slides'],
            tags: [],
            industries: [],
        }
    },
    computed: {
        marketReportAvatar() {
            return this.$_.isArray(this.marketReport.individual_slides) && this.marketReport.individual_slides.length
                ? this.marketReport.individual_slides[0]
                : null
        }
    },
    async mounted() {
        const marketReportId = this.$route.params.id

        await this.getIndustries();

        this.tags = await Tag.get();

        if (marketReportId) {
            this.marketReport = await MarketReport.include(['tags']).find(marketReportId)
        } else {
            let nextRank = await window.axios.get('/api/helpers/next-rank/market_reports');
            this.marketReport.rank = nextRank.data
            this.$forceUpdate()
        }
        this.loading = false;
    },
    methods: {
        async handleSave() {
            if (this.$_.isArray(this.marketReport.tags)) {
                this.marketReport.tags = this.marketReport.tags.map((tag) => this.$_.isObject(tag) ? tag.id : tag);
            }

            const marketReport = new MarketReport(this.marketReport)
            this.marketReport = await marketReport.save()
            this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})
            if (this.marketReport.id && !this.$route.params.id) {
                await this.$router.push({name: 'admin.market-reports.show', params: {id: this.marketReport.id}})
            }
        },

        async getIndustries() {
            this.industries = await Industry.get();

            if (!this.marketReport.industry_id) {
                this.marketReport.industry_id = this.industries[0].id
            }
        },
        ...mapMutations(['showSnackBar']),
    }
}
</script>

<style lang="scss">
.w-100 {
    width: 100%;
}
</style>
