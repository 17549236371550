<template>
    <v-list-item-group color="primary" class="mb-4">
        <h2 class="ml-3" v-if="name" v-text="name"/>

        <v-list-item v-for="(item, fileIdx) in files" :key="fileIdx">
            <v-list-item-content>
                <v-list-item-title class="py-1">
                    <v-btn class="mx-3" x-small :href="item" target="_blank">Download</v-btn>
                    <v-btn class="mx-3" x-small @click="remove(fileIdx)">Remove</v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="py-1">
                    <v-row>
                        <v-flex>
                            <vue-dropzone
                                id="dropzoneNewLanguage"
                                ref="languageDropzone"
                                class="mt-n5 admin-upload-files"
                                @vdropzone-complete="success"
                                :options="dropzoneOptions"/>
                        </v-flex>
                        <v-flex v-if="model.url">
                            <v-btn class="mt-n2 ml-4" @click="addFile" v-text="'Add'"/>
                        </v-flex>
                    </v-row>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list-item-group>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'


export default {
    name: 'AdminUploadFiles',
    components : {
        vueDropzone: vue2Dropzone
    },
    props: {
        name: {
            type: String,
            default: null
        },
        directory: {
            type: String,
            required: true
        },
        file: {
            required: true
        },
        isMultiply: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            files: [],
            filesTemp: [],
            model: {
                url: ''
            },
            dropzoneOptions: {
                url: '/api/upload',
                maxFilesize: 10,
                maxFiles: this.isMultiply ? 99 : 1,
                params: {'directory': this.directory},
                headers: {
                    Authorization: null
                },
                previewTemplate:  `
                    <div class="dz-preview dz-file-preview" style="min-width: 120px">
                        <div class="dz-details">
                            <div class="dz-filename"><span data-dz-name></span></div>
                            <div class="dz-size" data-dz-size></div>
                        </div>
                        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                    </div>
                `
            },
        }
    },
    async mounted() {
        if (this.isMultiply) {
            this.files = this.file;
        } else {
            this.files.push(this.file);
        }

        const token = await localStorage.getItem('auth_token_default');

        this.dropzoneOptions.headers.Authorization = "Bearer " + token
    },
    methods: {
        addFile() {
            if (this.isMultiply) {
                this.files = this.files.concat(this.filesTemp);
                this.filesTemp = [];
                this.$emit('input', this.files);
            } else {
                this.files = [this.model.url];
                this.$emit('input', this.model.url);
            }

            this.$refs.languageDropzone.removeAllFiles();
            this.model.url = ''
        },

        remove(fileIdx) {
            this.files.splice(fileIdx, 1);

            if (this.isMultiply) {
                this.$emit('input', this.files);
            } else {
                this.$emit('input', '');
            }
        },

        success(response) {
            this.model.url = JSON.parse(response.xhr.response).url

            if (this.isMultiply) {
                this.filesTemp.push(this.model.url);
            }
        }
    },

    watch: {
        file() {
            this.files = this.isMultiply
                ? this.file
                : (this.file ? [this.file] : null);
        }
    }
}
</script>

<style lang="scss">
#dropzoneNewLanguage {
    min-height: 30px;
    padding: 0 5px;

    .dz-message {
        padding: 20px;
    }
}
</style>
